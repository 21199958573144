import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import CustomModal from '@artemis/components/Modal';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import {
  loadIncentives,
  toggleListModal,
  toggleDetailsModal,
  updateIncentives,
} from '@artemis/store/incentives/slice';
import {
  getIncentiveGroups,
  getIsListModalOpen,
  getIsListModalSelectable,
  getIsDetailsModalOpen,
  getIncentivesById,
  getIsLoading,
  getGroupsByIncentiveId,
} from '@artemis/store/incentives/selectors';
import { getMerchantId } from '@artemis/store/merchant/selectors';
import IncentiveGroup from '@artemis/components/Incentives/IncentiveGroup';
import Button from '@artemis/containers/CheckoutPage/Button';
import { useRouter } from 'next/router';
import { getCheckoutCodeFromQuery } from '@artemis/utils/promo';
import {
  logMenuViewIncentivesImpression,
  logSelectIncentiveClick,
  logViewIncentiveDetailsClick,
  logViewIncentivesImpression,
} from './analytics';

const Modal = styled(CustomModal)`
  width: 100%;
  background-color: ${props => props.theme.palette.background.card};
  border-radius: 4pt;
  height: 100%;
  overflow-y: auto;
  :focus {
    outline: none;
  }
  ${({ theme }) => theme.isTablet`
    width: 467px;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    margin: 50vh auto;
    transform: translateY(-50%);
  `}
`;

const CloseModal = styled.button`
  z-index: 1;
  background: ${props => props.theme.palette.common.white};
  box-shadow: ${props => props.theme.shadows.shadow1};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  top: 14px;
  left: 14px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  position: absolute;
`;

const CloseIcon = styled(ResponsiveImage).attrs({
  id: 'close.svg.img',
})`
  height: 20px;
  width: 20px;
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 70px 16px 36px;
  box-sizing: border-box;
  justify-content: space-between;
  height: 100%;
  ${({ theme }) => theme.noScrollbars};
  ${({ theme }) => theme.isTablet`
    border-radius: 4pt;
    padding: 70px 0 0;
  `}
`;

const Title = styled.h2`
  ${props => props.theme.typography.h4};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-bottom: 1rem;

  ${props => props.theme.isTablet`
    text-align: center;
  `}
`;

const Description = styled.p`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.rtColors.black900};
  margin-bottom: 38px;

  ${props => props.theme.isTablet`
    text-align: center;
  `}
`;

const Content = styled.div`
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const GroupContainer = styled.div`
  ${({ theme }) => theme.isTablet`
    padding: 0 24px;
  `}
`;

const ActionsContainer = styled.div`
  border-top: solid 1px ${props => props.theme.rtColors.ui5};
  ${({ theme }) => theme.isTablet`
    padding: 1rem 24px;
  `}
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const unselectIdsFromGroup = (selectedIds, group) => {
  const selectedIncentiveIdsInGroup = group.incentives
    .filter(incentive => selectedIds.includes(incentive.incentiveId))
    .map(incentive => incentive.incentiveId);

  return selectedIds.filter(id => !selectedIncentiveIdsInGroup.includes(id));
};

const IncentivesListModal = () => {
  const dispatch = useDispatch();
  const { query } = useRouter();
  const [selectedIds, setSelectedIds] = useState([]);

  const { authenticated, initialized } = useContext(AuthenticationContext);

  const isListModalOpen = useSelector(getIsListModalOpen);
  const isSelectable = useSelector(getIsListModalSelectable);
  const isDetailsModalOpen = useSelector(getIsDetailsModalOpen);
  const merchantId = useSelector(getMerchantId);
  const incentiveGroups = useSelector(getIncentiveGroups);
  const incentivesById = useSelector(getIncentivesById);
  const groupsByIncentiveId = useSelector(getGroupsByIncentiveId);
  const isLoading = useSelector(getIsLoading);

  const cartCheckoutCode = getCheckoutCodeFromQuery(query);

  const onViewIncentiveDetails = incentiveId => {
    const incentive = incentivesById[incentiveId];
    if (!incentive) {
      return;
    }

    logViewIncentiveDetailsClick({
      merchantId,
      isSelected: selectedIds.includes(incentiveId),
      incentive,
    });
    dispatch(toggleDetailsModal({ isOpen: true, incentiveId }));
  };

  const onToggleIncentive = incentiveId => {
    const incentive = incentivesById[incentiveId];
    const group = groupsByIncentiveId[incentiveId];

    if (!incentive || !group) {
      return;
    }

    if (isLoading) {
      return;
    }

    const isCurrentlySelected = selectedIds.includes(incentiveId);
    const isModifiable = incentive.modifiable;
    const isValid = incentive.valid;
    const groupSupportsMultiple = group.canSelectMultiple;

    const canSelect = !isCurrentlySelected && isModifiable && isValid;
    const canUnselect = isCurrentlySelected && isModifiable;

    if (!canSelect && !canUnselect) {
      return;
    }

    logSelectIncentiveClick({
      merchantId,
      isSelected: isCurrentlySelected,
      incentive,
    });

    if (canUnselect) {
      setSelectedIds(selectedIds.filter(id => id !== incentiveId));
    } else if (canSelect) {
      // If user can only claim one incentive from the group, unselect the others automatically
      setSelectedIds(
        groupSupportsMultiple
          ? [...selectedIds, incentiveId]
          : [...unselectIdsFromGroup(selectedIds, group), incentiveId],
      );
    }
  };

  const onCloseModal = () => {
    if (isSelectable) {
      onSaveSelectedIncentives();
    } else {
      dispatch(toggleListModal({ isOpen: false }));
    }
  };

  const onSaveSelectedIncentives = () => {
    const incentives = selectedIds
      .map(id => incentivesById[id])
      .filter(incentive => !!incentive)
      .map(({ incentiveId, incentiveType }) => ({
        incentiveId,
        incentiveType,
      }));

    dispatch(
      updateIncentives({
        merchantId,
        incentives,
        ...(cartCheckoutCode && { cartCheckoutCode }),
      }),
    );
  };

  useEffect(() => {
    setSelectedIds(
      Object.values(incentivesById)
        .filter(incentive => incentive.selected)
        .map(incentive => incentive.incentiveId),
    );
  }, [incentivesById]);

  // Load incentives on page load
  useEffect(() => {
    if (initialized) {
      dispatch(
        loadIncentives({ merchantId, cartCheckoutCode, forCart: isSelectable }),
      );
    }
  }, [merchantId, authenticated, initialized]);

  // Reload incentives when modal opens
  useEffect(() => {
    if (isListModalOpen) {
      dispatch(
        loadIncentives({ merchantId, cartCheckoutCode, forCart: isSelectable }),
      );
    }
  }, [isListModalOpen]);

  // Send analytic event when modal opens
  useEffect(() => {
    if (isListModalOpen) {
      const incentiveIds = Object.keys(incentivesById);
      if (isSelectable) {
        logViewIncentivesImpression({ merchantId, incentiveIds });
      } else {
        logMenuViewIncentivesImpression({ merchantId, incentiveIds });
      }
    }
  }, [isListModalOpen]);

  // Scroll to invalid incentives automatically when modal opens
  useEffect(() => {
    if (isSelectable && isListModalOpen) {
      setTimeout(() => {
        const firstInvalidIncentive = document.querySelectorAll(
          '[id^="invalid-incentive-"]',
        )[0];
        if (firstInvalidIncentive?.scrollIntoView) {
          firstInvalidIncentive.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 10);
    }
  }, [isSelectable, isListModalOpen]);

  return (
    <Modal
      isOpen={isListModalOpen && !isDetailsModalOpen}
      size="large"
      onRequestClose={onCloseModal}
    >
      <ModalContainer>
        <CloseModal onClick={onCloseModal}>
          <CloseIcon />
        </CloseModal>

        <Title>
          <FormattedMessage entry="menu.incentives.listModalTitle" />
        </Title>

        {!isSelectable && (
          <Description>
            <FormattedMessage entry="menu.incentives.listModalDescription" />
          </Description>
        )}

        <Content>
          <GroupContainer>
            {incentiveGroups.map(group => (
              <IncentiveGroup
                key={group.title}
                group={group}
                readOnly={!isSelectable}
                selectedIds={selectedIds}
                onViewIncentiveDetails={onViewIncentiveDetails}
                onToggleIncentive={onToggleIncentive}
              />
            ))}
          </GroupContainer>
          <ActionsContainer>
            {isSelectable ? (
              <StyledButton
                isLoading={isLoading}
                onClick={onSaveSelectedIncentives}
              >
                <FormattedMessage entry="menu.incentives.saveButton" />
              </StyledButton>
            ) : (
              <StyledButton onClick={() => dispatch(toggleListModal(false))}>
                <FormattedMessage entry="menu.incentives.okButton" />
              </StyledButton>
            )}
          </ActionsContainer>
        </Content>
      </ModalContainer>
    </Modal>
  );
};

export default IncentivesListModal;
