import PropTypes from 'prop-types';

export const IncentiveShape = PropTypes.shape({
  incentiveId: PropTypes.string,
  title: PropTypes.string,
  terms: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.bool,
  modifiable: PropTypes.bool,
  valid: PropTypes.bool,
  fundingSource: PropTypes.string,
  errorText: PropTypes.string,
  expirationTimestamp: PropTypes.number,
});

export const IncentiveGroupShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  canSelectMultiple: PropTypes.bool,
  incentives: PropTypes.arrayOf(IncentiveShape),
});
