import { useMemo } from 'react';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { BACK_URL_PARAM } from '@artemis/utils/query/constants';
import usePersistedQuery from '@artemis/utils/query/usePersistedQuery';
import { JOIN_TEAM_PAGE_PATH } from '../constants';

const useJoinTeamUrl = () => {
  const router = useRouter();
  const persistedQuery = usePersistedQuery();

  const url = useMemo(() => {
    const query = {
      ...(router.isReady && { [BACK_URL_PARAM]: router.asPath }),
    };
    return queryString.stringifyUrl({
      url: `${JOIN_TEAM_PAGE_PATH}${persistedQuery}`,
      query,
    });
  }, [router.isReady, router.asPath, persistedQuery]);

  return url;
};

export default useJoinTeamUrl;
