import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IncentiveCard from './IncentiveCard';
import { IncentiveGroupShape } from './propTypes';

const Container = styled.div`
  margin-bottom: 0.875rem;
  margin-bottom: 38px;
`;

const Title = styled.h3`
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.rtColors.black900};
  margin-bottom: 0;
`;

const Description = styled.p`
  ${props => props.theme.typography.bodySmall};
`;

const IncentiveGroup = ({
  group,
  readOnly,
  selectedIds,
  onViewIncentiveDetails,
  onToggleIncentive,
}) => (
  <Container>
    <Title>{group.title}</Title>
    {group.description && <Description>{group.description}</Description>}
    {group.incentives.map(incentive => (
      <IncentiveCard
        key={incentive.incentiveId}
        incentive={{
          ...incentive,
          selected: selectedIds.includes(incentive.incentiveId),
        }}
        readOnly={readOnly}
        onViewIncentiveDetails={onViewIncentiveDetails}
        onToggleIncentive={onToggleIncentive}
      />
    ))}
  </Container>
);

IncentiveGroup.propTypes = {
  group: IncentiveGroupShape.isRequired,
  readOnly: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onViewIncentiveDetails: PropTypes.func.isRequired,
  onToggleIncentive: PropTypes.func.isRequired,
};

export default IncentiveGroup;
