import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { getIsMerchantDisplayModeDineIn } from '@artemis/store/merchant/selectors';
import {
  DINE_IN_TYPE_PARAM,
  DINE_IN_DESCRIPTION_PARAM,
} from '@artemis/utils/query/constants';

const useIsDineIn = () => {
  const { query } = useRouter();
  const {
    [DINE_IN_DESCRIPTION_PARAM]: dineInDescription,
    [DINE_IN_TYPE_PARAM]: dineInType,
  } = query;
  const isMerchantDisplayModeDineIn = useSelector(
    getIsMerchantDisplayModeDineIn,
  );

  return !!dineInDescription || !!dineInType || isMerchantDisplayModeDineIn;
};

export default useIsDineIn;
