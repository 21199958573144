import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';
import { AppStore, GooglePlay } from '@artemis/components/Images';
import StandardModal from '@artemis/components/StandardModal';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';
import useBranchLink from '@artemis/utils/useBranchLink';
import { PROMO_VALUES } from '@artemis/utils/query/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 8px 16px;
`;

export const Title = styled.h1`
  ${props => props.theme.typography.h3};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  text-align: center;
  margin-bottom: 18px;
`;

export const SubTitle = styled.h3`
  ${props => props.theme.typography.subtitle1};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  text-align: center;
  margin-bottom: 32px;
`;

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
`;

const Badges = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  > * {
    margin-right: 16px;
    :last-child {
      margin-right: 0;
    }
  }
`;

const BadgeLink = styled.a.attrs({ target: '_blank' })``;

const DownloadAppModal = ({ isOpen, onClose }) => {
  const branchUrl = useBranchLink({ promoCode: PROMO_VALUES.GET_RITUAL });

  return (
    <StandardModal size="lg" isOpen={isOpen} onClose={onClose}>
      <Container>
        <Title>
          <FormattedMessage entry="login.download.modalTitle" />
        </Title>
        <SubTitle>
          <FormattedMessage entry="login.download.modalSubtitle" />
        </SubTitle>
        <QRCodeContainer>
          <QRCodeSVG value={branchUrl} size="160" />
        </QRCodeContainer>
        <Badges>
          <BadgeLink
            href={useFormatMessage({ entry: 'login.download.googlePlayLink' })}
          >
            <GooglePlay />
          </BadgeLink>
          <BadgeLink
            href={useFormatMessage({ entry: 'login.download.appStoreLink' })}
          >
            <AppStore />
          </BadgeLink>
        </Badges>
      </Container>
    </StandardModal>
  );
};

DownloadAppModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DownloadAppModal;
