import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingSpinner } from '@ritualco/ritual-frontend-components';

const StyledButton = styled.button`
  background-color: ${props => props.theme.palette.primary};
  color: ${props => props.theme.palette.common.white};
  ${props => props.theme.typography.button};

  text-decoration: none;
  display: flex;
  justify-content: center;
  min-width: 160px;
  box-sizing: border-box;
  padding: 10px 24px;
  border-radius: 4pt;
  border: none;
  cursor: pointer;
  transition: 0.25s background-color ease;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &:hover {
    background-color: ${props =>
      !props.disabled &&
      props.theme.palette.hover.onLightBg(props.theme.palette.primary)};
  }

  width: 100%;
  ${props => props.theme.isTablet`
    width: unset;
  `}
`;

const SIZES = {
  small: 12,
  default: 16,
  large: 20,
};

const SpinnerContainer = styled.div`
  position: absolute;

  ${props =>
    props.position === 'left'
      ? `left: -${SIZES[props.size] * 1.5}px;`
      : `right: -${SIZES[props.size] * 1.5}px;`}

  top: 50%;
  transform: translateY(-50%);
  display: flex;
`;

const TextContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Button = ({
  disabled = false,
  isLoading = false,
  loaderSize = 'default',
  loaderPosition = 'left',
  children,
  ...props
}) => (
  <StyledButton disabled={disabled || isLoading} {...props}>
    <TextContainer>
      {isLoading && (
        <SpinnerContainer
          role="alert"
          aria-busy="true"
          size={loaderSize}
          position={loaderPosition}
        >
          <LoadingSpinner size={SIZES[loaderSize]} />
        </SpinnerContainer>
      )}
      {children}
    </TextContainer>
  </StyledButton>
);

Button.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loaderSize: PropTypes.oneOf(['default', 'small', 'large']),
  loaderPosition: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
};

export default Button;
