import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useIsDineIn from '@artemis/containers/Cart/useIsDineIn';
import { useRemoteConfig } from '@artemis/integrations/remoteConfig';
import { getOrderSource } from '@artemis/store/order/selectors';
import { breakpoints } from '@artemis/theme/breakpoints';
import Cookies from '@artemis/utils/cookies';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import { ORDER_SOURCE } from './constants';
import {
  HIDE_DOWNLOAD_APP_PARAM,
  IN_STORE_PARAM,
  ORIGIN_PARAM,
  ORIGIN_VALUES,
} from './query/constants';

export const DOWNLOAD_APP_MODAL_COOKIE = 'rw_downloadapp_modal';

export const useDownloadAppEnabled = () => {
  const { query } = useRouter();
  const { getBoolean } = useRemoteConfig();
  const orderSource = useSelector(getOrderSource);

  const isFeatureFlagEnabled = getBoolean('rw_download_app_enabled');
  const isInStore = useQueryCheck(IN_STORE_PARAM);
  const isHideDownloadAppPresent = useQueryCheck(HIDE_DOWNLOAD_APP_PARAM);
  const isDineIn = useIsDineIn();
  const isOrderSourceRitualApp = orderSource === ORDER_SOURCE.RITUAL_APP;
  const isOriginApp = query[ORIGIN_PARAM] === ORIGIN_VALUES.APP;

  return (
    isFeatureFlagEnabled &&
    !isInStore &&
    !isDineIn &&
    !isHideDownloadAppPresent &&
    isOrderSourceRitualApp &&
    !isOriginApp
  );
};

const setDownloadAppModalCookie = () => {
  Cookies.set(DOWNLOAD_APP_MODAL_COOKIE, true, {
    expires: 7,
    domain: process.env.RT_SHARED_COOKIE_DOMAIN,
  });
};

export const useDownloadAppModal = () => {
  const [isDownloadAppModalOpen, setIsDownloadAppModalOpen] = useState(false);
  const isDownloadAppEnabled = useDownloadAppEnabled();

  // Initially opens modal in mobile
  useEffect(() => {
    const isCookieSet = Cookies.get(DOWNLOAD_APP_MODAL_COOKIE);
    const isMobile = window.innerWidth <= breakpoints.mobileMax;

    if (isDownloadAppEnabled && !isCookieSet && isMobile) {
      setIsDownloadAppModalOpen(true);
    }
  }, [isDownloadAppEnabled]);

  const onDownloadAppModalClose = () => {
    setDownloadAppModalCookie();
    setIsDownloadAppModalOpen(false);
  };

  return [isDownloadAppModalOpen, onDownloadAppModalClose];
};
