import styled from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';

export const GooglePlay = styled(ResponsiveImage).attrs({
  id: 'icons.googlePlay.img',
})`
  display: inline-block;
  height: 40px;
  width: 134px;
`;
