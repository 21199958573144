import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import StandardModal from '@artemis/components/StandardModal';
import ConfirmLeaveModal from '@artemis/components/Teams/ConfirmLeaveModal';
import TeamDetails from '@artemis/components/Teams/TeamDetails';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import {
  closeMyTeamModal,
  leaveTeam,
  loadTeamById,
  setLeaveTeamModalOpen,
} from '@artemis/store/teams/slice';
import {
  getIsLeaveTeamLoading,
  getIsLeaveTeamModalOpen,
  getIsMyTeamModalOpen,
  getIsTeamByIdLoading,
  getIsTeamSecured,
  getTeamByIdInitialized,
  getTeamFloorName,
  getTeamLocationName,
  getTeamName,
  getTeamRequiresEmailVerification,
  getTeamWorkEmail,
} from '@artemis/store/teams/selectors';
import { getUserTeamDefaultTeamId } from '@artemis/store/user/selectors';
import useJoinTeamUrl from '@artemis/containers/Teams/JoinTeamPage/useJoinTeamUrl';

const Title = styled.h2`
  ${props => props.theme.typography.h3};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-bottom: 32px;
  text-align: center;
`;

const StyledTeamDetails = styled(TeamDetails)`
  padding-bottom: 36px;
`;

export const TeamDetailsModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(getIsMyTeamModalOpen);
  const defaultTeamId = useSelector(getUserTeamDefaultTeamId);
  const teamName = useSelector(getTeamName);
  const isTeamSecured = useSelector(getIsTeamSecured);
  const workEmail = useSelector(getTeamWorkEmail);
  const isEmailVerificationRequired = useSelector(
    getTeamRequiresEmailVerification,
  );
  const locationName = useSelector(getTeamLocationName);
  const floorName = useSelector(getTeamFloorName);
  const isLeaveTeamModalOpen = useSelector(getIsLeaveTeamModalOpen);
  const isLeaveTeamLoading = useSelector(getIsLeaveTeamLoading);
  const isTeamLoading = useSelector(getIsTeamByIdLoading);
  const isTeamInitialized = useSelector(getTeamByIdInitialized);

  const verifyEmailUrl = useJoinTeamUrl();

  const isTeamMember = !!defaultTeamId;
  const leaveTeamProps = {
    secondaryButtonText: (
      <FormattedMessage entry="teams.teamDetailsModal.secondaryButtonText" />
    ),
    secondaryButtonType: 'warning',
    onClickSecondaryButton: () => dispatch(setLeaveTeamModalOpen(true)),
  };

  useEffect(() => {
    if (isOpen && defaultTeamId) {
      dispatch(loadTeamById(defaultTeamId));
    }
  }, [isOpen, defaultTeamId]);

  if (isLeaveTeamModalOpen) {
    return (
      <ConfirmLeaveModal
        isOpen
        teamName={teamName}
        isLoading={isLeaveTeamLoading}
        onConfirm={() => dispatch(leaveTeam(defaultTeamId))}
        onCancel={() => dispatch(setLeaveTeamModalOpen(false))}
      />
    );
  }

  return (
    <StandardModal
      isOpen={isOpen}
      onClose={() => dispatch(closeMyTeamModal())}
      primaryButtonText={
        <FormattedMessage entry="teams.teamDetailsModal.primaryButtonText" />
      }
      size="lg"
      onClickPrimaryButton={() => dispatch(closeMyTeamModal())}
      {...(isTeamMember && leaveTeamProps)}
    >
      <Title>
        <FormattedMessage entry="teams.teamDetailsModal.title" />
      </Title>
      <StyledTeamDetails
        name={teamName}
        workEmail={workEmail}
        secured={isTeamSecured}
        needsEmailVerification={isEmailVerificationRequired}
        location={locationName}
        floor={floorName}
        verifyEmailUrl={verifyEmailUrl}
        isLoading={isTeamLoading && !isTeamInitialized}
      />
    </StandardModal>
  );
};

export default TeamDetailsModal;
