import styled from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';

export const AppStore = styled(ResponsiveImage).attrs({
  id: 'icons.appStore.img',
})`
  display: inline-block;
  height: 40px;
  width: 120px;
`;
