import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { rtColors } from '@ritualco/ritual-frontend-components';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { IncentiveShape } from './propTypes';
import FundingSource from './FundingSource';
import ExpirationDate from './ExpirationDate';
import SelectChip from './SelectChip';

const Container = styled.div`
  border: ${props => (props.selected ? '2px' : '1px')} solid
    ${props =>
      props.selected
        ? props.theme.palette.primary
        : props.theme.rtColors.black200};
  border-radius: 10px;
  margin-top: 0.75rem;
  padding: 0.75rem 1rem;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h4`
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.rtColors.black900};
`;

const StyledExpirationDate = styled(ExpirationDate)`
  ${props => props.theme.typography.caption}
`;

const ViewDetailsLink = styled.a`
  ${props => props.theme.typography.bodySmall};
  color: ${({ theme }) => theme.palette.primary};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  text-decoration: none;
`;

const ErrorMessage = styled.div`
  padding-bottom: 16px;
  color: ${props => props.theme.rtColors.red700};
  ${props => props.theme.typography.bodySmall};
  padding-bottom: 0;
  padding-top: 8px;
`;

const SelectButton = styled.button`
  align-self: stretch;
  background: none;
  border: none;
  text-align: right;
  border: none;
`;

const IncentiveCard = ({
  incentive,
  readOnly,
  onViewIncentiveDetails,
  onToggleIncentive,
}) => (
  <Container
    selected={!readOnly && incentive.selected}
    id={`${incentive.valid ? 'valid' : 'invalid'}-incentive-${
      incentive.incentiveId
    }`}
  >
    <Content>
      <div>
        <Title>{incentive.title}</Title>
        <FundingSource incentive={incentive} />
        {!!incentive.expirationTimestamp && (
          <StyledExpirationDate incentive={incentive} />
        )}
        <ViewDetailsLink
          onClick={e => {
            e.preventDefault();
            onViewIncentiveDetails(incentive.incentiveId);
          }}
          href={`#incentive-details-${incentive.incentiveId}`}
        >
          <FormattedMessage entry="menu.incentives.viewDetailsLink" />
        </ViewDetailsLink>
      </div>

      {!readOnly && (
        <SelectButton
          type="button"
          onClick={() => onToggleIncentive(incentive.incentiveId)}
        >
          <SelectChip incentive={incentive} />
        </SelectButton>
      )}
    </Content>

    {!readOnly && !incentive.valid && (
      <ErrorMessage>
        <ErrorOutlineIcon
          sx={{
            color: rtColors.red700,
            fontSize: 17,
            marginRight: '8px',
            verticalAlign: 'middle',
          }}
        />
        {incentive.errorText}
      </ErrorMessage>
    )}
  </Container>
);

IncentiveCard.propTypes = {
  incentive: IncentiveShape.isRequired,
  readOnly: PropTypes.bool,
  onViewIncentiveDetails: PropTypes.func.isRequired,
  onToggleIncentive: PropTypes.func.isRequired,
};

export default IncentiveCard;
