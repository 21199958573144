import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';

import 'react-loading-skeleton/dist/skeleton.css';

const DetailsRow = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${props => props.theme.rtColors.black200};
  :first-child {
    padding-top: 0;
  }
`;

const DetailsTitle = styled.p`
  ${props => props.theme.typography.caption};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.rtColors.black800};
  margin-bottom: 8px;
`;

const DetailsValue = styled.p``;

const iconTextCss = css`
  margin-top: 8px;
  display: flex;
  align-items: center;
  > svg {
    margin-right: 8px;
  }
`;

const SecureTeam = styled.div`
  ${iconTextCss}
  color: ${props => props.theme.rtColors.black1000};
`;

const UnverifiedEmail = styled.a`
  ${iconTextCss}
  color: ${props => props.theme.palette.primary};
  text-decoration: none;
`;

const DetailsRowSkeleton = styled(Skeleton)`
  margin-bottom: 34px;
  border-radius: 6px;
`;

const TeamDetails = ({
  className,
  name,
  secured = false,
  workEmail,
  needsEmailVerification = false,
  location,
  floor,
  verifyEmailUrl,
  isLoading = false,
}) => {
  if (isLoading) {
    return (
      <>
        <DetailsRowSkeleton height={82} />
        <DetailsRowSkeleton height={50} count={3} />
      </>
    );
  }
  return (
    <div className={className}>
      {name && (
        <DetailsRow>
          <DetailsTitle>
            <FormattedMessage entry="teams.myTeam" />
          </DetailsTitle>
          <DetailsValue>{name}</DetailsValue>
          {secured && (
            <SecureTeam>
              <LockIcon sx={{ fontSize: '16px' }} />
              <FormattedMessage entry="teams.secureTeam" />
            </SecureTeam>
          )}
        </DetailsRow>
      )}
      {workEmail && (
        <DetailsRow>
          <DetailsTitle>
            <FormattedMessage entry="teams.workEmail" />
          </DetailsTitle>
          <DetailsValue>{workEmail}</DetailsValue>
          {needsEmailVerification && (
            <UnverifiedEmail href={verifyEmailUrl}>
              <ErrorOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
              <FormattedMessage entry="teams.verifyWorkEmail" />
            </UnverifiedEmail>
          )}
        </DetailsRow>
      )}
      {location && (
        <DetailsRow>
          <DetailsTitle>
            <FormattedMessage entry="teams.location" />
          </DetailsTitle>
          <DetailsValue>{location}</DetailsValue>
        </DetailsRow>
      )}
      {floor && (
        <DetailsRow>
          <DetailsTitle>
            <FormattedMessage entry="teams.myFloor" />
          </DetailsTitle>
          <DetailsValue>{floor}</DetailsValue>
        </DetailsRow>
      )}
    </div>
  );
};

TeamDetails.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  workEmail: PropTypes.string,
  secured: PropTypes.bool,
  needsEmailVerification: PropTypes.bool,
  location: PropTypes.string,
  floor: PropTypes.string,
  verifyEmailUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default TeamDetails;
