import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { FORMAT, formatDate, parseTimestamp } from '@artemis/utils/time-format';
import { IncentiveShape } from './propTypes';

const Container = styled.p`
  color: ${props => props.theme.rtColors.black800};
  margin: 0.5rem 0;
`;

const formatExpiryDate = incentive => {
  const { expirationTimestamp } = incentive;
  const date = parseTimestamp(expirationTimestamp);
  return formatDate(date, FORMAT.DATE_AND_TIME);
};

const ExpirationDate = ({ className, incentive }) => (
  <Container className={className}>
    <FormattedMessage
      entry="menu.incentives.availableUntil"
      values={{
        date: formatExpiryDate(incentive),
      }}
    />
  </Container>
);

ExpirationDate.propTypes = {
  className: PropTypes.string,
  incentive: IncentiveShape,
};

export default ExpirationDate;
