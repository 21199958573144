import React, { useEffect } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { RtButton } from '@ritualco/ritual-frontend-components';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CustomModal from '@artemis/components/Modal';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import {
  getIsRedeemModalOpen,
  getPointsToRedeem,
  getRedeemedCreditsPreview,
  getTotalCreditsAfterRedeemPreview,
  getIsRedeeming,
  getPointsIncrement,
  getMaxRedeemablePoints,
} from '@artemis/store/points/selectors';
import { getActiveCurrencyCredits } from '@artemis/store/user/selectors';
import {
  closeRedeemModal,
  updatePointsToRedeem,
  loadRedeemModalPreview,
  redeemPoints,
} from '@artemis/store/points/slice';
import { getMerchantName } from '@artemis/store/merchant/selectors';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { track } from '@artemis/utils/analytics';
import { EVENT_ACTION_REDEEM_OVERLAY_CLICK } from './analytics';

const Modal = styled(CustomModal)`
  width: 100%;
  background-color: ${props => props.theme.palette.background.card};
  border-radius: 4pt;
  height: 100%;
  overflow-y: auto;
  :focus {
    outline: none;
  }
  ${({ theme }) => theme.isTablet`
    width: 467px;
    max-height: 485px;
    margin: 0 auto;
    margin: 50vh auto;
    transform: translateY(-50%);
  `}
`;

const CloseModal = styled.button`
  z-index: 1;
  background: ${props => props.theme.palette.common.white};
  box-shadow: ${props => props.theme.shadows.shadow1};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  top: 14px;
  left: 14px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  position: absolute;
`;

const CloseIcon = styled(ResponsiveImage).attrs({
  id: 'close.svg.img',
})`
  height: 20px;
  width: 20px;
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 70px 16px 36px;
  box-sizing: border-box;
  text-align: center;
  justify-content: space-between;
  height: 100%;
  ${({ theme }) => theme.noScrollbars};
  ${({ theme }) => theme.isTablet`
    border-radius: 4pt;
    padding: 70px 24px 24px;
  `}
`;

const Title = styled.h2`
  ${props => props.theme.typography.bodyLarge};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  color: ${props => props.theme.rtColors.black900};
  margin-bottom: 20px;
`;

const Description = styled.p`
  ${props => props.theme.typography.bodySmall};
  color: ${props => props.theme.rtColors.black900};
  margin-bottom: 40px;
`;

const Content = styled.div`
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${props => props.theme.isTablet`
    max-width: 311px;
  `}
`;

const RedeemPreview = styled.p`
  margin-top: 15px;
  ${props => props.theme.typography.bodyLarge};
  font-weight: ${props => props.theme.typography.fontWeightBold};
`;

const BalancePreview = styled.p`
  margin-top: 3rem;
  margin-bottom: 11px;
`;

const PointCountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 19px;
  border-bottom: 1px solid #e4e4e4;
`;

const PointsText = styled.div`
  ${props => props.theme.typography.bodyLarge};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  color: ${props => props.theme.rtColors.teal500};
  margin: 0 2.1rem;
`;

const RedeemPointsModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(getIsRedeemModalOpen);
  const maxRedeemablePoints = useSelector(getMaxRedeemablePoints);
  const merchantName = useSelector(getMerchantName);
  const currentCreditBalance = useSelector(getActiveCurrencyCredits);
  const pointsToRedeem = useSelector(getPointsToRedeem);
  const redeemedCreditsPreview = useSelector(getRedeemedCreditsPreview);
  const newCreditBalancePreview = useSelector(
    getTotalCreditsAfterRedeemPreview,
  );
  const isRedeeming = useSelector(getIsRedeeming);
  const pointsIncrement = useSelector(getPointsIncrement);

  const allowIncrement =
    pointsToRedeem + pointsIncrement <= maxRedeemablePoints;
  const allowDecrement = pointsToRedeem - pointsIncrement >= pointsIncrement;

  const incrementPoints = () => {
    dispatch(updatePointsToRedeem(pointsToRedeem + pointsIncrement));
  };

  const decrementPoints = () => {
    dispatch(updatePointsToRedeem(pointsToRedeem - pointsIncrement));
  };

  const handleRedeem = () => {
    track(EVENT_ACTION_REDEEM_OVERLAY_CLICK, {
      MERCHANT_NAME: merchantName,
    });
    dispatch(redeemPoints());
  };

  useEffect(() => {
    dispatch(updatePointsToRedeem(maxRedeemablePoints));
  }, [maxRedeemablePoints]);

  useEffect(() => {
    if (isOpen) {
      dispatch(loadRedeemModalPreview());
    }
  }, [pointsToRedeem, isOpen]);

  return (
    <Modal isOpen={isOpen} size="large">
      <ModalContainer>
        <CloseModal onClick={() => dispatch(closeRedeemModal())}>
          <CloseIcon />
        </CloseModal>

        <Title>
          <FormattedMessage entry="menu.points.redeemTitle" />
        </Title>
        <Description>
          <FormattedMessage
            entry={
              merchantName
                ? 'menu.points.redeemDescription.merchant'
                : 'menu.points.redeemDescription.generic'
            }
            values={{ merchantName, credits: currentCreditBalance }}
          />
        </Description>

        <Content>
          <div>
            <PointCountContainer>
              <RtButton
                variant="outlined"
                onClick={decrementPoints}
                disabled={!allowDecrement}
                icon={
                  <RemoveRoundedIcon
                    sx={{ color: 'currentColor', fontSize: 18 }}
                  />
                }
                size="small"
              />
              <PointsText>
                <FormattedMessage
                  entry="menu.points.pointsCount"
                  values={{ points: pointsToRedeem.toLocaleString() }}
                />
              </PointsText>
              <RtButton
                variant="outlined"
                onClick={incrementPoints}
                disabled={!allowIncrement}
                icon={
                  <AddRoundedIcon
                    sx={{ color: 'currentColor', fontSize: 18 }}
                  />
                }
                size="small"
              />
            </PointCountContainer>
            <RedeemPreview>{redeemedCreditsPreview}</RedeemPreview>
          </div>

          <div>
            <BalancePreview>
              <FormattedMessage
                entry="menu.points.newCreditBalance"
                values={{ credits: newCreditBalancePreview }}
              />
            </BalancePreview>
            <RtButton
              variant="tint1"
              onClick={handleRedeem}
              loading={isRedeeming}
              fullWidth
            >
              <FormattedMessage entry="menu.points.redeemButton" />
            </RtButton>
          </div>
        </Content>
      </ModalContainer>
    </Modal>
  );
};

export default RedeemPointsModal;
