import React, { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import styled, { css } from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { logMenuViewIncentivesClick } from '@artemis/containers/Incentives/analytics';
import IncentiveDetailsModal from '@artemis/containers/Incentives/IncentiveDetailsModal';
import IncentivesListModal from '@artemis/containers/Incentives/IncentivesListModal';
import RedeemPointsModal from '@artemis/containers/RedeemPointsModal';
import TeamDetailsModal from '@artemis/containers/Teams/TeamDetailsModal';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { toggleListModal } from '@artemis/store/incentives/slice';
import { getTotalIncentivesCount } from '@artemis/store/incentives/selectors';
import {
  getCurrencyCode,
  getMerchantId,
  getMerchantName,
} from '@artemis/store/merchant/selectors';
import {
  getActiveCurrencyCredits,
  getUserEmail,
  getUserName,
  getUserExternalId,
  getUserTeamDefaultTeamId,
} from '@artemis/store/user/selectors';
import {
  getTotalRedeemableCredits,
  getMaxRedeemablePoints,
  getCanUserRedeemPoints,
} from '@artemis/store/points/selectors';
import { loadUser } from '@artemis/store/user/slice';
import {
  openRedeemModal,
  loadRedeemAllPreview,
} from '@artemis/store/points/slice';
import { track } from '@artemis/utils/analytics';
import {
  getIsEmbedded,
  getIsEmbeddedHqO,
} from '@artemis/store/embed/selectors';
import { openMyTeamModal } from '@artemis/store/teams/slice';
import { logJoinYourTeamClick } from '@artemis/containers/Teams/JoinTeamPage/analytics';
import {
  JOIN_TEAM_PAGE_PATH,
  JOIN_TEAM_PAGE_PATH_V2,
} from '@artemis/containers/Teams/constants';
import useJoinTeamUrl from '@artemis/containers/Teams/JoinTeamPage/useJoinTeamUrl';
import { PROMO_CODE_PARAM } from '@artemis/utils/query/constants';
import { EVENT_ACTION_REDEEM_ACCOUNT_DROPDOWN_CLICK } from './analytics';

const Dropdown = styled.div`
  position: relative;
  background-color: ${props =>
    props.$theme === 'primary' ? 'transparent' : props.theme.palette.grey[200]};
  border-radius: 1000pt;
`;

const Toggle = styled.button`
  align-items: stretch;
  background: none;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  padding: 6px 16px;
`;

const Menu = styled.div`
  background: ${props => props.theme.palette.common.white};
  border-radius: 5px;
  box-shadow: ${props => props.theme.shadows.shadow1};
  width: 200px;
  overflow: hidden;
  position: absolute;
  right: 0;
`;

const menuItemCss = css`
  display: block;
  max-width: min(400px, 80vw);
  overflow: hidden;
  padding: 8px 16px;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
      &:hover {
        background: ${props.theme.palette.grey.UI6};
      }
    `}
`;

const MenuItem = styled.button`
  ${menuItemCss};
  background: none;
  border: none;
`;

const MenuItemLink = styled.a`
  ${menuItemCss};
  text-decoration: none;
  color: ${props => props.theme.rtColors.black1000};
  width: auto;
`;

const BoldedMenuItem = styled.div`
  ${menuItemCss};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  width: unset;
`;

const ProfileIcon = styled(ResponsiveImage)`
  margin-right: 7px;
  display: flex;
`;

const CaretIcon = styled(ResponsiveImage)`
  margin-left: 7px;
`;

const UserName = styled.div`
  ${props => props.theme.typography.caption};
  ${props => props.theme.typography.overflowEllipsis};
  margin-top: 4px;
`;

const LoadingBlock = styled.div`
  height: 100%;
  min-height: 25px;
  width: 100px;
  border-radius: 20px;
  background: lightgrey;
`;

const UserMenu = ({ className, signout, signInTheme = 'primary' }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const userName = useSelector(getUserName);
  const userEmail = useSelector(getUserEmail);
  const userExternalId = useSelector(getUserExternalId);
  const defaultTeamId = useSelector(getUserTeamDefaultTeamId);
  const currency = useSelector(getCurrencyCode);
  const currentCredits = useSelector(getActiveCurrencyCredits);
  const redeemableCredits = useSelector(getTotalRedeemableCredits);
  const totalPoints = useSelector(getMaxRedeemablePoints);
  const canRedeemPoints = useSelector(getCanUserRedeemPoints);
  const merchantName = useSelector(getMerchantName);
  const isEmbedded = useSelector(getIsEmbedded);
  const isEmbeddedHqO = useSelector(getIsEmbeddedHqO);
  const merchantId = useSelector(getMerchantId);
  const incentivesCount = useSelector(getTotalIncentivesCount);

  const joinTeamUrl = useJoinTeamUrl();

  const { [PROMO_CODE_PARAM]: promoCode } = router.query;

  const showRedeemPoints = currency && canRedeemPoints;
  const hideSignOutLink = isEmbedded && !isEmbeddedHqO;
  const canUpdatePoints = userExternalId && currency;
  const showTeamsLink = ![JOIN_TEAM_PAGE_PATH, JOIN_TEAM_PAGE_PATH_V2].includes(
    router.pathname,
  );

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (canUpdatePoints) {
      dispatch(loadRedeemAllPreview());
    }
  }, [currency, totalPoints, userExternalId]);

  useClickAway(ref, () => setIsOpen(false));
  if (!userName) {
    return <LoadingBlock data-testid="userProfileLoading" />;
  }

  const redeemPoints = () => {
    track(EVENT_ACTION_REDEEM_ACCOUNT_DROPDOWN_CLICK, {
      MERCHANT_NAME: merchantName,
    });
    dispatch(openRedeemModal());
  };

  const onPromotionsClick = () => {
    logMenuViewIncentivesClick({ merchantId });
    dispatch(toggleListModal({ isOpen: true }));
  };

  const onViewTeamClick = () => {
    dispatch(openMyTeamModal());
  };

  return (
    <Dropdown
      className={className}
      ref={ref}
      data-testid="userMenu"
      $theme={signInTheme}
    >
      <Toggle
        aria-label={userEmail}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="dropdown-menu-toggle"
      >
        <ProfileIcon id="icons.profile.img" />
        <CaretIcon id={isOpen ? 'icons.caretUp.img' : 'icons.caretDown.img'} />
      </Toggle>

      {isOpen && (
        <Menu data-testid="dropdown-menu">
          <BoldedMenuItem>
            <FormattedMessage
              entry="login.menu.welcome"
              values={{ name: userName }}
            />
            <UserName>{userEmail}</UserName>
          </BoldedMenuItem>
          {showTeamsLink &&
            (defaultTeamId ? (
              <MenuItem onClick={onViewTeamClick}>
                <FormattedMessage entry="login.menu.myTeam" />
              </MenuItem>
            ) : (
              <MenuItemLink
                href={joinTeamUrl}
                onClick={() => logJoinYourTeamClick({ promoCode })}
              >
                <FormattedMessage entry="login.menu.joinTeam" />
              </MenuItemLink>
            ))}
          {currency && (
            <MenuItem>
              <FormattedMessage
                entry="login.menu.credits"
                values={{ credits: currentCredits }}
              />
            </MenuItem>
          )}
          {showRedeemPoints && (
            <MenuItem onClick={redeemPoints}>
              <FormattedMessage
                entry="login.menu.redeemPoints"
                values={{ credits: redeemableCredits }}
              />
            </MenuItem>
          )}
          {incentivesCount > 0 && (
            <MenuItem onClick={onPromotionsClick}>
              <FormattedMessage
                entry="login.menu.promotions"
                values={{ count: incentivesCount }}
              />
            </MenuItem>
          )}

          {!hideSignOutLink && (
            <MenuItem onClick={signout}>
              <FormattedMessage entry="global.signOut" />
            </MenuItem>
          )}
        </Menu>
      )}
      <TeamDetailsModal />
      <RedeemPointsModal />
      <IncentivesListModal />
      <IncentiveDetailsModal />
    </Dropdown>
  );
};

UserMenu.propTypes = {
  className: PropTypes.string,
  signout: PropTypes.func.isRequired,
  signInTheme: PropTypes.oneOf(['primary', 'secondary']),
};

export default UserMenu;
