import React, { useContext, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DownloadAppModal from '@artemis/components/DownloadApp/DownloadAppDesktopModal';
import { logDesktopButtonClick } from '@artemis/components/DownloadApp/analytics';
import IncentiveDetailsModal from '@artemis/containers/Incentives/IncentiveDetailsModal';
import IncentivesListModal from '@artemis/containers/Incentives/IncentivesListModal';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import RemoteConfigContext from '@artemis/integrations/remoteConfig/context';
import {
  getMerchantData,
  getMerchantId,
} from '@artemis/store/merchant/selectors';
import { track } from '@artemis/utils/analytics';
import { useDownloadAppEnabled } from '@artemis/utils/downloadApp';
import { isInIframe } from '@artemis/utils/iframe';
import { MERCHANT_GROUP_PARAM } from '@artemis/utils/query/constants';
import { EVENT_ACTION_NAV_LOGIN_CLICK } from './analytics';
import UserMenu from '../UserMenu';

const LoginModal = dynamic(() => import('@artemis/containers/LoginModal'), {
  ssr: false,
});

const Button = styled.button`
  background: none;
  background-color: ${({ $theme, theme }) =>
    $theme === 'primary' ? theme.palette.primary : theme.palette.grey[200]};
  border: none;
  border-radius: 1000pt;
  color: ${({ $theme, theme }) =>
    $theme === 'primary'
      ? theme.palette.common.white
      : theme.rtColors.black1000};
  cursor: pointer;
  display: flex;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  font-family: ${props => props.theme.typography.poppins};
  font-size: '0.875rem';
  padding: 8px 16px;
  justify-content: center;
  min-width: 80px;
`;

const LoadingBlock = styled.div`
  height: 100%;
  min-height: 36px;
  width: 80px;
  border-radius: 100px;
  background: lightgrey;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const QrContainer = styled.span`
  display: none;
  ${props => props.theme.isTablet`
    display: flex;
  `}
`;

const GetAppButton = () => {
  const isDownloadAppEnabled = useDownloadAppEnabled();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const merchantId = useSelector(getMerchantId);

  const closeDownloadModal = () => setShowDownloadModal(false);
  const openDownloadModal = () => {
    logDesktopButtonClick(merchantId);
    setShowDownloadModal(true);
  };

  return isDownloadAppEnabled ? (
    <>
      <DownloadAppModal
        isOpen={showDownloadModal}
        onClose={closeDownloadModal}
      />
      <QrContainer>
        <Button onClick={openDownloadModal} data-testid="getAppButton">
          <QrCodeIcon sx={{ marginRight: '4px' }} />
          <FormattedMessage entry="login.download.getApp" />
        </Button>
      </QrContainer>
    </>
  ) : null;
};

const AccountControls = ({ className, signInTheme = 'primary' }) => {
  const router = useRouter();
  const merchantData = useSelector(getMerchantData);

  const { configLoaded } = useContext(RemoteConfigContext);
  const { logout, authenticated, initialized, setAuthModal, allowLogin } =
    useContext(AuthenticationContext);

  if (isInIframe()) {
    return null;
  }

  if (!configLoaded || !initialized) {
    return <LoadingBlock data-testid="config-loading" />;
  }

  if (authenticated) {
    return (
      <ControlsContainer>
        <GetAppButton />
        <UserMenu signout={logout} signInTheme={signInTheme} />
      </ControlsContainer>
    );
  }

  // Do not show login prompts for the HqO linkout integration
  if (!allowLogin) {
    return null;
  }

  return (
    <>
      <LoginModal />
      <IncentivesListModal />
      <IncentiveDetailsModal />
      <ControlsContainer>
        <GetAppButton />
        <Button
          className={className}
          $theme={signInTheme}
          data-testid="logInButton"
          onClick={() => {
            track(EVENT_ACTION_NAV_LOGIN_CLICK, {
              GROUP_ID: router.query?.[MERCHANT_GROUP_PARAM],
              MERCHANT_ID: merchantData?.id,
              MERCHANT_NAME: merchantData?.name,
            });
            setAuthModal({
              showLoginModal: true,
            });
          }}
        >
          <FormattedMessage entry="login.signin" />
        </Button>
      </ControlsContainer>
    </>
  );
};

AccountControls.propTypes = {
  className: PropTypes.string,
  signInTheme: PropTypes.oneOf(['primary', 'secondary']),
};

export default AccountControls;
