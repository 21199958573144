import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { IncentiveShape } from './propTypes';

const Container = styled.div`
  margin: 0.5rem 0;
`;

const Source = styled.div`
  display: inline-block;
  ${props => props.theme.typography.overhead}
  color: ${props => props.theme.rtColors.black900};
  background-color: ${props => props.theme.rtColors.black100};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  padding: 0.25rem;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
`;

const FUNDING_SOURCE_THIRD_PARTY = 'THIRD_PARTY';

const FundingSource = ({ className, incentive }) => {
  if (
    !incentive.fundingSource ||
    incentive.fundingSource === FUNDING_SOURCE_THIRD_PARTY
  ) {
    return null;
  }

  return (
    <Container className={className}>
      <Source>
        <FormattedMessage
          entry="menu.incentives.fundingSource"
          values={{ source: incentive.fundingSource }}
        />
      </Source>
    </Container>
  );
};

FundingSource.propTypes = {
  className: PropTypes.string,
  incentive: IncentiveShape,
};

export default FundingSource;
