import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CustomModal from '@artemis/components/Modal';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { toggleDetailsModal } from '@artemis/store/incentives/slice';
import {
  getIncentiveForDetailsModal,
  getIsDetailsModalOpen,
} from '@artemis/store/incentives/selectors';
import ExpirationDate from '@artemis/components/Incentives/ExpirationDate';
import FundingSource from '@artemis/components/Incentives/FundingSource';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { logViewIncentiveDetailsImpression } from './analytics';

const Modal = styled(CustomModal)`
  width: 100%;
  background-color: ${props => props.theme.palette.background.card};
  border-radius: 4pt;
  height: 100%;
  overflow-y: auto;
  :focus {
    outline: none;
  }
  ${({ theme }) => theme.isTablet`
    width: 467px;
    max-height: 820px;
    margin: 0 auto;
    margin: 50vh auto;
    transform: translateY(-50%);
    height: auto;
  `}
`;

const StyledExpirationDate = styled(ExpirationDate)`
  ${props => props.theme.typography.body};
`;

const StyledFundingSource = styled(FundingSource)`
  margin-top: 0;
`;

const CloseModal = styled.button`
  z-index: 1;
  background: ${props => props.theme.palette.common.white};
  box-shadow: ${props => props.theme.shadows.shadow1};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  top: 14px;
  left: 14px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  position: absolute;
`;

const LeftChevronIcon = styled(ResponsiveImage).attrs({
  id: 'icons.chevronLeft.img',
})`
  height: 20px;
  width: 20px;
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 70px 16px 36px;
  box-sizing: border-box;
  justify-content: space-between;
  height: 100%;
  ${({ theme }) => theme.noScrollbars};
  ${({ theme }) => theme.isTablet`
    border-radius: 4pt;
    padding: 70px 24px 24px;
  `}
`;

const Title = styled.h2`
  ${props => props.theme.typography.h4};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-bottom: 1rem;
`;

const FieldLabel = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.rtColors.black};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  margin-top: 0.875rem;
`;

const Content = styled.div`
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TermsList = styled.ul`
  margin: 0;
  margin-top: 0.75rem;
`;

const Term = styled.li`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.rtColors.black800};
`;

const IncentiveDetailsModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(getIsDetailsModalOpen);
  const incentive = useSelector(getIncentiveForDetailsModal);

  useEffect(() => {
    if (isOpen) {
      logViewIncentiveDetailsImpression({ incentive });
    }
  }, [isOpen]);

  if (!incentive) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} size="large">
      <ModalContainer>
        <CloseModal
          onClick={() => dispatch(toggleDetailsModal({ isOpen: false }))}
        >
          <LeftChevronIcon />
        </CloseModal>
        <Title>{incentive.title}</Title>
        <Content>
          <StyledFundingSource incentive={incentive} />
          {!!incentive.expirationTimestamp && (
            <>
              <FieldLabel>
                <FormattedMessage entry="menu.incentives.expiration" />
              </FieldLabel>
              <StyledExpirationDate incentive={incentive} />
            </>
          )}
          {incentive.terms.length > 0 && (
            <>
              <FieldLabel>
                <FormattedMessage entry="menu.incentives.details" />
              </FieldLabel>
              <TermsList>
                {incentive.terms.map(term => (
                  <Term key={term}>{term}</Term>
                ))}
              </TermsList>
            </>
          )}
        </Content>
      </ModalContainer>
    </Modal>
  );
};

export default IncentiveDetailsModal;
