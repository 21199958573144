import { createSelector } from 'reselect';
import { TEAM_MEMBERSHIP_STATUS } from '@artemis/containers/Teams/constants';

const getTeamsRoot = state => state.teams;

const getTeamByIdRoot = createSelector(getTeamsRoot, teams => teams?.teamById);

export const getIsMyTeamModalOpen = createSelector(
  getTeamByIdRoot,
  teamById => teamById?.isModalOpen || false,
);

export const getIsTeamByIdLoading = createSelector(
  getTeamByIdRoot,
  teamById => teamById?.isLoading || false,
);

export const getTeamById = createSelector(
  getTeamByIdRoot,
  teamById => teamById?.data,
);

export const getTeamByIdInitialized = createSelector(
  getTeamByIdRoot,
  teamById => teamById.initialized || false,
);

export const getTeamByIdError = createSelector(
  getTeamByIdRoot,
  teamById => teamById.error,
);

export const getTeamDetails = createSelector(
  getTeamById,
  teamById => teamById?.teamDetails,
);

export const getTeamName = createSelector(
  getTeamDetails,
  teamDetails => teamDetails?.name || '',
);

export const getIsTeamSecured = createSelector(
  getTeamDetails,
  teamDetails => teamDetails?.secured || false,
);

export const getTeamUserSettings = createSelector(
  getTeamById,
  teamById => teamById?.userSettings,
);

export const getTeamWorkEmail = createSelector(
  getTeamUserSettings,
  userSettings => userSettings?.workEmail || '',
);

export const getTeamLocationId = createSelector(
  getTeamUserSettings,
  userSettings => userSettings?.locationId,
);

export const getTeamFloorId = createSelector(
  getTeamUserSettings,
  userSettings => userSettings?.floorId,
);

export const getTeamLocations = createSelector(
  getTeamById,
  team => team?.locations || [],
);

export const getTeamLocation = createSelector(
  getTeamLocations,
  getTeamLocationId,
  (locations, locationId) =>
    locations.find(loc => loc.locationId === locationId),
);

export const getTeamLocationName = createSelector(
  getTeamLocation,
  location => location?.displayName || '',
);

export const getTeamFloors = createSelector(
  getTeamLocation,
  location => location?.floors || [],
);

export const getTeamFloor = createSelector(
  getTeamFloors,
  getTeamFloorId,
  (floors, floorId) => floors.find(floor => floor.floorId === floorId),
);

export const getTeamFloorName = createSelector(
  getTeamFloor,
  floor => floor?.name || '',
);

const getUserMembership = createSelector(
  getTeamById,
  team => team?.userMembership,
);

const getIsUserEmailVerified = createSelector(
  getUserMembership,
  userMembership => userMembership?.emailVerified || false,
);

export const getTeamRequiresEmailVerification = createSelector(
  getIsTeamSecured,
  getIsUserEmailVerified,
  (isTeamSecured, isUserEmailVerified) => {
    if (!isTeamSecured) return false;
    return !isUserEmailVerified;
  },
);

export const getHasUserJoinedTeam = createSelector(
  getUserMembership,
  userMembership => userMembership?.status === TEAM_MEMBERSHIP_STATUS.MEMBER,
);

export const getIsEmailVerified = createSelector(
  getUserMembership,
  userMembership => userMembership?.emailVerified || false,
);

export const getIsVerifiedOnTeam = createSelector(
  getHasUserJoinedTeam,
  getIsEmailVerified,
  (hasJoined, isVerified) => hasJoined && isVerified,
);

const getSearchTeamsRoot = createSelector(getTeamsRoot, teams => teams.search);

export const getTeamsSearchResults = createSelector(
  getSearchTeamsRoot,
  search => search?.data?.results || [],
);

export const getTeamsSearching = createSelector(
  getSearchTeamsRoot,
  search => search?.isLoading,
);

export const getTeamsSearchError = createSelector(
  getSearchTeamsRoot,
  search => search?.error,
);

const getJoinRoot = createSelector(getTeamsRoot, teams => teams.join);

export const getIsJoinLoading = createSelector(
  getJoinRoot,
  join => join.isLoading || false,
);

const getLeaveTeamRoot = createSelector(getTeamsRoot, teams => teams.leave);

export const getIsLeaveTeamModalOpen = createSelector(
  getLeaveTeamRoot,
  leave => leave.isModalOpen,
);

export const getIsLeaveTeamLoading = createSelector(
  getLeaveTeamRoot,
  leave => leave.isLoading,
);

const getAddTeamLocationRoot = createSelector(
  getTeamsRoot,
  teams => teams.addLocation,
);

export const getIsAddTeamLocationLoading = createSelector(
  getAddTeamLocationRoot,
  addLocation => addLocation.isLoading,
);

export const getIsAddTeamLocationModalOpen = createSelector(
  getAddTeamLocationRoot,
  addLocation => addLocation.isModalOpen,
);

const getSendVerificationEmailRoot = createSelector(
  getTeamsRoot,
  teams => teams.sendVerificationEmail,
);

export const getIsSendVerificationEmailLoading = createSelector(
  getSendVerificationEmailRoot,
  sendVerificationEmail => sendVerificationEmail.isLoading,
);

const getSendVerificationEmailError = createSelector(
  getSendVerificationEmailRoot,
  sendVerificationEmail => sendVerificationEmail.error,
);

export const getSendVerificationEmailErrorCode = createSelector(
  getSendVerificationEmailError,
  error => error?.response?.data?.errors?.[0]?.code,
);

const getVerificationStatusRoot = createSelector(
  getTeamsRoot,
  teams => teams.verificationStatus,
);

export const getIsVerificationStatusLoading = createSelector(
  getVerificationStatusRoot,
  verificationStatus => verificationStatus.isLoading,
);

export const getVerificationStatusTeamName = createSelector(
  getVerificationStatusRoot,
  verificationStatus => verificationStatus.data?.associatedTeamDetails?.name,
);

export const getIsCreateTeamLoading = createSelector(
  getTeamsRoot,
  teams => teams.create.isLoading,
);
