import {
  EVENT_PARAM_TYPE_STRING,
  logEvent,
  EVENT_TYPE_CORE_FLOW,
  PROJECT_CATEGORY_DOWNLOAD_APP,
} from '@artemis/utils/analytics';
import { OS_TYPE } from '@artemis/utils/constants';

const EVENT_ACTION_DOWNLOAD_APP_MODAL_IMPRESSION =
  'DOWNLOAD_APP_MODAL_IMPRESSION';
const EVENT_ACTION_DOWNLOAD_APP_GET_APP_BUTTON_CLICK =
  'DOWNLOAD_APP_GET_APP_BUTTON_CLICK';
export const EVENT_ACTION_DOWNLOAD_APP_MODAL_CONTINUE_IN_APP_CLICK =
  'DOWNLOAD_APP_MODAL_CONTINUE_IN_APP_CLICK';
export const EVENT_ACTION_DOWNLOAD_APP_MODAL_CONTINUE_IN_BROWSER_CLICK =
  'DOWNLOAD_APP_MODAL_CONTINUE_IN_BROWSER_CLICK';

const getAndroidOrIOS = () => {
  try {
    const userAgent = global.navigator.userAgent || window.opera;
    if (/android/i.test(userAgent)) {
      return OS_TYPE.ANDROID;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return OS_TYPE.IOS;
    }
    return undefined;
  } catch {
    return undefined;
  }
};

const getDeviceOSParam = () => {
  const deviceOS = getAndroidOrIOS();
  return deviceOS
    ? {
        key: 'DEVICE_OS',
        value: deviceOS,
        type: EVENT_PARAM_TYPE_STRING,
      }
    : undefined;
};

const getMerchantIdParam = merchantId => ({
  key: 'MERCHANT_ID',
  value: merchantId,
  type: EVENT_PARAM_TYPE_STRING,
});

export const logMobileModalImpression = merchantId => {
  const params = [];
  const deviceOSParam = getDeviceOSParam();
  if (deviceOSParam) params.push(deviceOSParam);
  if (merchantId) params.push(getMerchantIdParam(merchantId));

  logEvent(
    EVENT_ACTION_DOWNLOAD_APP_MODAL_IMPRESSION,
    params,
    EVENT_TYPE_CORE_FLOW,
    PROJECT_CATEGORY_DOWNLOAD_APP,
  );
};

export const logMobileModalButtonClick = eventName => {
  const deviceOSParam = getDeviceOSParam();
  logEvent(
    eventName,
    deviceOSParam ? [deviceOSParam] : [],
    EVENT_TYPE_CORE_FLOW,
    PROJECT_CATEGORY_DOWNLOAD_APP,
  );
};

export const logDesktopButtonClick = merchantId => {
  const params = merchantId ? [getMerchantIdParam(merchantId)] : [];

  logEvent(
    EVENT_ACTION_DOWNLOAD_APP_GET_APP_BUTTON_CLICK,
    params,
    EVENT_TYPE_CORE_FLOW,
    PROJECT_CATEGORY_DOWNLOAD_APP,
  );
};
