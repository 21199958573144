import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { RtChip } from '@ritualco/ritual-frontend-components';
import { lighten } from 'polished';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { IncentiveShape } from './propTypes';

const StyledRtChip = styled(RtChip)`
  display: inline-flex;
  height: 31px;
  border-radius: 6px;

  ${props => props.theme.typography.bodySmall};
  ${props => props.selected && `padding-left: .5rem;`}
  ${props =>
    props.variant === 'teal' && `color: ${props.theme.palette.primary};`}
  ${props =>
    props.variant === 'teal' &&
    `background-color: ${lighten(0.49, props.theme.palette.primary)};`}

  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const SelectChip = ({ incentive }) => {
  const selectedText = incentive.selected ? (
    <>
      <CheckIcon
        sx={{
          color: 'currentColor',
          fontSize: 17,
          marginRight: '3px',
          verticalAlign: 'middle',
        }}
      />
      <FormattedMessage entry="menu.incentives.selected" />
    </>
  ) : (
    <FormattedMessage entry="menu.incentives.select" />
  );

  if (!incentive.modifiable || (!incentive.selected && !incentive.valid)) {
    return (
      <StyledRtChip variant="inactive" selected={incentive.selected}>
        {selectedText}
      </StyledRtChip>
    );
  }

  if (incentive.selected && !incentive.valid) {
    return (
      <StyledRtChip variant="outlined">
        <FormattedMessage entry="menu.incentives.remove" />
      </StyledRtChip>
    );
  }

  return (
    <StyledRtChip variant="teal" selected={incentive.selected}>
      {selectedText}
    </StyledRtChip>
  );
};

SelectChip.propTypes = {
  incentive: IncentiveShape,
};

export default SelectChip;
