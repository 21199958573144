import { createSelector } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { getCart } from '../cart/selectors';

const getIncentivesRoot = state => state.incentives;

export const getIncentiveGroups = createSelector(
  getIncentivesRoot,
  incentives => incentives?.data?.incentiveGroups || [],
);

export const getIncentivesList = createSelector(getIncentiveGroups, groups =>
  groups
    .map(group => group.incentives)
    .reduce(
      (allIncentives, incentives) => [...allIncentives, ...incentives],
      [],
    ),
);

export const getIncentivesById = createSelector(getIncentivesList, incentives =>
  keyBy(incentives, 'incentiveId'),
);

export const getGroupsByIncentiveId = createSelector(
  getIncentiveGroups,
  groups => {
    const result = {};
    groups.forEach(group => {
      group.incentives.forEach(incentive => {
        result[incentive.incentiveId] = group;
      });
    });

    return result;
  },
);

export const getTotalIncentivesCount = createSelector(
  getIncentiveGroups,
  groups => {
    let total = 0;
    groups.forEach(group => {
      total += group?.incentives?.length || 0;
    });

    return total;
  },
);

export const getIsLoading = createSelector(
  getIncentivesRoot,
  incentives => incentives.isFetching || incentives.isUpdating,
);

export const getCartIncentives = createSelector(
  getCart,
  cart => cart?.incentives || [],
);

export const getSelectedIncentives = createSelector(
  getCartIncentives,
  getIncentivesById,
  (cartIncentives, incentivesById) =>
    cartIncentives.map(incentive => ({
      ...incentivesById[incentive.incentiveId],
      ...incentive,
    })),
);

export const getCartInvalidIncentives = createSelector(
  getCartIncentives,
  cartIncentives => cartIncentives.filter(incentive => !incentive.valid),
);

export const getIsListModalOpen = createSelector(
  getIncentivesRoot,
  incentives => incentives.listModal.isOpen,
);

export const getIsListModalSelectable = createSelector(
  getIncentivesRoot,
  incentives => incentives.listModal.isSelectable,
);

const getDetailsModal = createSelector(
  getIncentivesRoot,
  incentives => incentives.detailsModal,
);

export const getIsDetailsModalOpen = createSelector(
  getDetailsModal,
  detailsModal => detailsModal.isOpen,
);

export const getIncentiveForDetailsModal = createSelector(
  getDetailsModal,
  getIncentivesById,
  (detailsModal, incentivesById) => incentivesById[detailsModal.incentiveId],
);
