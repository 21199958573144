import {
  EVENT_PARAM_TYPE_STRING,
  logEvent,
  EVENT_PARAM_TYPE_BOOL,
  EVENT_TYPE_INTERACTION,
} from '@artemis/utils/analytics';

export const EVENT_ACTION_VIEW_INCENTIVES_CLICK = 'VIEW_INCENTIVES_CLICK';
export const EVENT_ACTION_VIEW_INCENTIVES_IMPRESSION =
  'VIEW_INCENTIVES_IMPRESSION';
export const EVENT_ACTION_SELECT_INCENTIVE_CLICK = 'SELECT_INCENTIVE_CLICK';
export const EVENT_ACTION_VIEW_INCENTIVE_DETAILS_CLICK =
  'VIEW_INCENTIVE_DETAILS_CLICK';
export const EVENT_ACTION_VIEW_INCENTIVE_DETAILS_IMPRESSION =
  'VIEW_INCENTIVE_DETAILS_IMPRESSION';
export const EVENT_ACTION_INVALID_INCENTIVE_ALERT_CLICK =
  'INVALID_INCENTIVE_ALERT_CLICK';
export const EVENT_ACTION_MENU_VIEW_INCENTIVES_CLICK =
  'MENU_VIEW_INCENTIVES_CLICK';
export const EVENT_ACTION_MENU_VIEW_INCENTIVES_IMPRESSION =
  'MENU_VIEW_INCENTIVES_IMPRESSION';

export const logMenuViewIncentivesClick = ({ merchantId }) => {
  logEvent(
    EVENT_ACTION_MENU_VIEW_INCENTIVES_CLICK,
    [
      {
        key: 'MERCHANT_ID',
        value: merchantId,
        type: EVENT_PARAM_TYPE_STRING,
      },
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logMenuViewIncentivesImpression = ({
  merchantId,
  incentiveIds,
}) => {
  logEvent(
    EVENT_ACTION_MENU_VIEW_INCENTIVES_IMPRESSION,
    [
      {
        key: 'MERCHANT_ID',
        value: merchantId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_IDS',
        value: incentiveIds.join('|'),
        type: EVENT_PARAM_TYPE_STRING,
      },
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logViewIncentivesClick = ({ merchantId }) => {
  logEvent(
    EVENT_ACTION_VIEW_INCENTIVES_CLICK,
    [
      {
        key: 'MERCHANT_ID',
        value: merchantId,
        type: EVENT_PARAM_TYPE_STRING,
      },
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logViewIncentivesImpression = ({ merchantId, incentiveIds }) => {
  logEvent(
    EVENT_ACTION_VIEW_INCENTIVES_IMPRESSION,
    [
      {
        key: 'MERCHANT_ID',
        value: merchantId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_IDS',
        value: incentiveIds.join('|'),
        type: EVENT_PARAM_TYPE_STRING,
      },
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logSelectIncentiveClick = ({
  merchantId,
  isSelected,
  incentive,
}) => {
  logEvent(
    EVENT_ACTION_SELECT_INCENTIVE_CLICK,
    [
      {
        key: 'MERCHANT_ID',
        value: merchantId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_ID',
        value: incentive.incentiveId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_STATE',
        value: isSelected ? 'SELECTED' : 'UNSELECTED',
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_SOURCE',
        value: incentive.fundingSource,
        type: EVENT_PARAM_TYPE_STRING,
      },
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logViewIncentiveDetailsClick = ({
  merchantId,
  isSelected,
  incentive,
}) => {
  logEvent(
    EVENT_ACTION_VIEW_INCENTIVE_DETAILS_CLICK,
    [
      {
        key: 'MERCHANT_ID',
        value: merchantId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_ID',
        value: incentive.incentiveId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_STATE',
        value: isSelected ? 'SELECTED' : 'UNSELECTED',
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_SOURCE',
        value: incentive.fundingSource,
        type: EVENT_PARAM_TYPE_STRING,
      },
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logViewIncentiveDetailsImpression = ({ incentive }) => {
  logEvent(
    EVENT_ACTION_VIEW_INCENTIVE_DETAILS_IMPRESSION,
    [
      {
        key: 'INCENTIVE_ID',
        value: incentive.incentiveId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_SOURCE',
        value: incentive.fundingSource,
        type: EVENT_PARAM_TYPE_STRING,
      },
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logInvalidIncentiveModalClick = ({
  merchantId,
  incentiveIds,
  wasReviewClicked,
}) => {
  logEvent(
    EVENT_ACTION_INVALID_INCENTIVE_ALERT_CLICK,
    [
      {
        key: 'MERCHANT_ID',
        value: merchantId,
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'INCENTIVE_IDS',
        value: incentiveIds.join('|'),
        type: EVENT_PARAM_TYPE_STRING,
      },
      {
        key: 'USER_REVIEW_PROMOTIONS_CLICK',
        value: wasReviewClicked ? 'TRUE' : 'FALSE',
        type: EVENT_PARAM_TYPE_BOOL,
      },
    ],
    EVENT_TYPE_INTERACTION,
  );
};
