import {
  logEvent,
  EVENT_PARAM_TYPE_INTEGER,
  EVENT_PARAM_TYPE_STRING,
  EVENT_PARAM_TYPE_BOOL,
  EVENT_TYPE_INTERACTION,
  PROJECT_CATEGORY_JOIN_TEAM,
} from '@artemis/utils/analytics';

const EVENT_ACTION_SELECT_TEAM_LOCATION_IMPRESSION =
  'SELECT_TEAM_LOCATION_IMPRESSION';
const EVENT_ACTION_SELECT_TEAM_LOCATION_SELECT_LOCATION_CLICK =
  'SELECT_TEAM_LOCATION_SELECT_LOCATION_CLICK';
const EVENT_ACTION_SELECT_TEAM_LOCATION_NEXT_CLICK =
  'SELECT_TEAM_LOCATION_NEXT_CLICK';
const EVENT_ACTION_SELECT_TEAM_LOCATION_ADD_LOCATION_CLICK =
  'SELECT_TEAM_LOCATION_ADD_LOCATION_CLICK';
const EVENT_ACTION_SELECT_TEAM_LOCATION_CANCEL_CLICK =
  'SELECT_TEAM_LOCATION_CANCEL_CLICK';

const EVENT_ACTION_VERIFY_WORK_EMAIL_IMPRESSION =
  'VERIFY_WORK_EMAIL_IMPRESSION';
const EVENT_ACTION_VERIFY_WORK_EMAIL_SUBMIT_EMAIL_CLICK =
  'VERIFY_WORK_EMAIL_SUBMIT_EMAIL_CLICK';
const EVENT_ACTION_VERIFY_WORK_EMAIL_CHECK_INBOX_IMPRESSION =
  'VERIFY_WORK_EMAIL_CHECK_INBOX_IMPRESSION';
const EVENT_ACTION_VERIFY_WORK_EMAIL_CHECK_INBOX_EDIT_CLICK =
  'VERIFY_WORK_EMAIL_CHECK_INBOX_EDIT_CLICK';
const EVENT_ACTION_VERIFY_WORK_EMAIL_CHECK_INBOX_CONTINUE_CLICK =
  'VERIFY_WORK_EMAIL_CHECK_INBOX_CONTINUE_CLICK';
const EVENT_ACTION_VERIFY_WORK_EMAIL_CHECK_INBOX_RESEND_CLICK =
  'VERIFY_WORK_EMAIL_CHECK_INBOX_RESEND_CLICK';
const EVENT_ACTION_SELECT_TEAM_FLOOR_IMPRESSION =
  'SELECT_TEAM_FLOOR_IMPRESSION';
const EVENT_ACTION_SELECT_TEAM_FLOOR_SELECT_FLOOR_CLICK =
  'SELECT_TEAM_FLOOR_SELECT_FLOOR_CLICK';
const EVENT_ACTION_SELECT_TEAM_FLOOR_NEXT_CLICK =
  'SELECT_TEAM_FLOOR_NEXT_CLICK';
const EVENT_ACTION_SELECT_TEAM_FLOOR_CANCEL_CLICK =
  'SELECT_TEAM_FLOOR_CANCEL_CLICK';
const EVENT_ACTION_JOIN_YOUR_TEAM_CLICK = 'JOIN_YOUR_TEAM_CLICK';
const EVENT_ACTION_ADD_TEAM_LOCATION_MODAL_IMPRESSION =
  'ADD_TEAM_LOCATION_MODAL_IMPRESSION';
const EVENT_ACTION_ADD_TEAM_LOCATION_MODAL_ADD_CLICK =
  'ADD_TEAM_LOCATION_MODAL_ADD_CLICK';
const EVENT_ACTION_FOOD_FEST_AT_WORK_IMPRESSION =
  'FOOD_FEST_AT_WORK_IMPRESSION';
const EVENT_ACTION_FOOD_FEST_AT_WORK_CONNECT_NOW_CLICK =
  'FOOD_FEST_AT_WORK_CONNECT_NOW_CLICK';
const EVENT_ACTION_CREATE_COMPANY_IMPRESSION = 'CREATE_COMPANY_IMPRESSION';
const EVENT_ACTION_CREATE_COMPANY_NEXT_CLICK = 'CREATE_COMPANY_NEXT_CLICK';

const EVENT_ACTION_REQUEST_TO_JOIN_TEAM_IMPRESSION =
  'REQUEST_TO_JOIN_TEAM_IMPRESSION';
const EVENT_ACTION_REQUEST_TO_JOIN_TEAM_BACK_CLICK =
  'REQUEST_TO_JOIN_TEAM_BACK_CLICK';

const parseEmailDomain = email => (email || '').split('@')[1] || '';

const emailDomainParam = email => ({
  key: 'EMAIL_DOMAIN_NAME',
  value: parseEmailDomain(email),
  type: EVENT_PARAM_TYPE_STRING,
});

const successParam = success => ({
  key: 'SUCCESS',
  value: success,
  type: EVENT_PARAM_TYPE_BOOL,
});

const promoCodeParam = promoCode => ({
  key: 'PROMO_CODE',
  value: promoCode,
  type: EVENT_PARAM_TYPE_STRING,
});

const teamIdParam = teamId => ({
  key: 'TEAM_ID',
  value: teamId,
  type: EVENT_PARAM_TYPE_STRING,
});

const companyNameParam = name => ({
  key: 'COMPANY_NAME',
  value: name,
  type: EVENT_PARAM_TYPE_STRING,
});

const googlePlaceIdParam = placeId => ({
  key: 'GOOGLE_PLACE_ID',
  value: placeId,
  type: EVENT_PARAM_TYPE_STRING,
});

const merchantIdParam = merchantId => ({
  key: 'MERCHANT_ID',
  value: merchantId,
  type: EVENT_PARAM_TYPE_STRING,
});

const locationIdParam = locationId => ({
  key: 'LOCATION_ID',
  value: locationId,
  type: EVENT_PARAM_TYPE_STRING,
});

const floorIdParam = floorId => ({
  key: 'FLOOR_ID',
  value: floorId,
  type: EVENT_PARAM_TYPE_STRING,
});

const rankParam = rank => ({
  key: 'RANK',
  value: rank,
  type: EVENT_PARAM_TYPE_INTEGER,
});

const logTeamEvent = (eventAction, eventParams = []) => {
  logEvent(
    eventAction,
    eventParams,
    EVENT_TYPE_INTERACTION,
    PROJECT_CATEGORY_JOIN_TEAM,
  );
};

export const logConnectWorkEmailImpression = () => {
  logTeamEvent(EVENT_ACTION_VERIFY_WORK_EMAIL_IMPRESSION);
};

export const logConnectWorkEmailSubmitClick = ({ email }) => {
  logTeamEvent(EVENT_ACTION_VERIFY_WORK_EMAIL_SUBMIT_EMAIL_CLICK, [
    emailDomainParam(email),
  ]);
};

export const logCheckInboxImpression = ({ email }) => {
  logTeamEvent(EVENT_ACTION_VERIFY_WORK_EMAIL_CHECK_INBOX_IMPRESSION, [
    emailDomainParam(email),
  ]);
};

export const logCheckInboxEditClick = ({ email }) => {
  logTeamEvent(EVENT_ACTION_VERIFY_WORK_EMAIL_CHECK_INBOX_EDIT_CLICK, [
    emailDomainParam(email),
  ]);
};

export const logCheckInboxContinueClick = ({ success, email }) => {
  logTeamEvent(EVENT_ACTION_VERIFY_WORK_EMAIL_CHECK_INBOX_CONTINUE_CLICK, [
    successParam(success),
    emailDomainParam(email),
  ]);
};

export const logCheckInboxResendClick = ({ email }) => {
  logTeamEvent(EVENT_ACTION_VERIFY_WORK_EMAIL_CHECK_INBOX_RESEND_CLICK, [
    emailDomainParam(email),
  ]);
};

export const logJoinYourTeamClick = ({ promoCode }) => {
  logTeamEvent(EVENT_ACTION_JOIN_YOUR_TEAM_CLICK, [promoCodeParam(promoCode)]);
};

export const logRequestToJoinImpression = ({ email }) => {
  logTeamEvent(EVENT_ACTION_REQUEST_TO_JOIN_TEAM_IMPRESSION, [
    emailDomainParam(email),
  ]);
};

export const logRequestToJoinBackClick = ({ email }) => {
  logTeamEvent(EVENT_ACTION_REQUEST_TO_JOIN_TEAM_BACK_CLICK, [
    emailDomainParam(email),
  ]);
};

export const logAddLocationModalImpression = ({ teamId }) => {
  logTeamEvent(EVENT_ACTION_ADD_TEAM_LOCATION_MODAL_IMPRESSION, [
    teamIdParam(teamId),
  ]);
};

export const logAddLocationModalSubmitClick = ({ teamId, googlePlaceId }) => {
  logTeamEvent(EVENT_ACTION_ADD_TEAM_LOCATION_MODAL_ADD_CLICK, [
    teamIdParam(teamId),
    googlePlaceIdParam(googlePlaceId),
  ]);
};

export const logEmployeePricingModalImpression = ({ merchantId }) => {
  logTeamEvent(EVENT_ACTION_FOOD_FEST_AT_WORK_IMPRESSION, [
    merchantIdParam(merchantId),
  ]);
};

export const logEmployeePricingModalConnectNowClick = ({ merchantId }) => {
  logTeamEvent(EVENT_ACTION_FOOD_FEST_AT_WORK_CONNECT_NOW_CLICK, [
    merchantIdParam(merchantId),
  ]);
};

export const logCreateCompanyImpression = ({ email }) => {
  logTeamEvent(EVENT_ACTION_CREATE_COMPANY_IMPRESSION, [
    emailDomainParam(email),
  ]);
};

export const logCreateCompanyNextClick = ({ name, googlePlaceId, email }) => {
  logTeamEvent(EVENT_ACTION_CREATE_COMPANY_NEXT_CLICK, [
    companyNameParam(name),
    googlePlaceIdParam(googlePlaceId),
    emailDomainParam(email),
  ]);
};

export const logSelectTeamLocationImpression = ({ teamId }) => {
  logTeamEvent(EVENT_ACTION_SELECT_TEAM_LOCATION_IMPRESSION, [
    teamIdParam(teamId),
  ]);
};

export const logSelectTeamLocationSelectLocationClick = ({
  teamId,
  locationId,
  rank,
}) => {
  logTeamEvent(EVENT_ACTION_SELECT_TEAM_LOCATION_SELECT_LOCATION_CLICK, [
    teamIdParam(teamId),
    locationIdParam(locationId),
    rankParam(rank),
  ]);
};

export const logSelectTeamLocationNextClick = ({ teamId, locationId }) => {
  logTeamEvent(EVENT_ACTION_SELECT_TEAM_LOCATION_NEXT_CLICK, [
    teamIdParam(teamId),
    locationIdParam(locationId),
  ]);
};

export const logSelectTeamLocationAddLocationClick = ({ teamId }) => {
  logTeamEvent(EVENT_ACTION_SELECT_TEAM_LOCATION_ADD_LOCATION_CLICK, [
    teamIdParam(teamId),
  ]);
};

export const logSelectTeamLocationCancelClick = ({ teamId }) => {
  logTeamEvent(EVENT_ACTION_SELECT_TEAM_LOCATION_CANCEL_CLICK, [
    teamIdParam(teamId),
  ]);
};

export const logSelectTeamFloorImpression = ({ teamId, locationId }) => {
  logTeamEvent(EVENT_ACTION_SELECT_TEAM_FLOOR_IMPRESSION, [
    teamIdParam(teamId),
    locationIdParam(locationId),
  ]);
};

export const logSelectTeamFloorSelectFloorClick = ({
  teamId,
  locationId,
  floorId,
  rank,
}) => {
  logTeamEvent(EVENT_ACTION_SELECT_TEAM_FLOOR_SELECT_FLOOR_CLICK, [
    teamIdParam(teamId),
    locationIdParam(locationId),
    floorIdParam(floorId),
    rankParam(rank),
  ]);
};

export const logSelectTeamFloorNextClick = ({
  teamId,
  locationId,
  floorId,
}) => {
  logTeamEvent(EVENT_ACTION_SELECT_TEAM_FLOOR_NEXT_CLICK, [
    teamIdParam(teamId),
    locationIdParam(locationId),
    floorIdParam(floorId),
  ]);
};

export const logSelectTeamFloorCancelClick = ({ teamId, locationId }) => {
  logTeamEvent(EVENT_ACTION_SELECT_TEAM_FLOOR_CANCEL_CLICK, [
    teamIdParam(teamId),
    locationIdParam(locationId),
  ]);
};
