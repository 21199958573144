import React from 'react';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loading from '@artemis/components/Loading';
import Modal from '@artemis/components/Modal';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { MODAL_SIZES } from '@artemis/utils/constants';

const ModalWrapper = styled(Modal)`
  width: 100%;
  background-color: ${props => props.theme.palette.background.card};
  border-radius: 4pt;
  overflow-y: auto;
  :focus {
    outline: none;
  }
  height: auto;
  transform: translateY(-50%);
  margin: 50vh auto;
  max-width: 316px;

  ${({ theme, size }) => theme.isTablet`
    max-width: ${MODAL_SIZES[size]};
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    margin: 50vh auto;
    transform: translateY(-50%);
  `}
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 33px 16px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  height: 100%;
  ${({ theme }) => theme.noScrollbars};
  ${({ theme }) => theme.isTablet`
    border-radius: 4pt;
    padding: 33px 32px 32px;
  `}
`;

const ModalContent = styled.div``;

const ActionsContainer = styled.div`
  padding: 1rem 0 0;
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: ${props => props.theme.rtColors.black100};
  color: ${props => props.theme.rtColors.red500};
  ${props => props.theme.typography.button};

  text-decoration: none;
  display: flex;
  justify-content: center;
  min-width: 160px;
  box-sizing: border-box;
  padding: 10px 24px;
  border-radius: 4pt;
  border: none;
  cursor: pointer;
  transition: 0.25s background-color ease;

  &:hover {
    background-color: ${props => props.theme.rtColors.red100};
  }
`;

const OutlinedButton = styled(StyledButton)`
  background-color: white;
  border: 1px solid ${props => props.theme.palette.primary};
  color: ${props => props.theme.palette.primary};
  margin-top: 12px;
  &:hover {
    background-color: ${props => lighten(0.51, props.theme.palette.primary)};
  }
`;

const Title = styled.h2`
  font-weight: ${props => props.theme.typography.fontWeightBold};
  font-size: 1.25rem;
  text-align: center;
  padding-bottom: 10px;
`;

const ConfirmLeaveModal = ({
  isOpen,
  teamName,
  isLoading,
  onConfirm,
  onCancel,
}) => (
  <ModalWrapper isOpen={isOpen} size="sm">
    <ModalContainer>
      <Loading isLoading={isLoading} />
      <ModalContent>
        <Title>
          <FormattedMessage
            entry="teams.confirmLeave.title"
            values={{ teamName }}
          />
        </Title>
      </ModalContent>
      <ActionsContainer>
        <StyledButton onClick={onConfirm}>
          <FormattedMessage entry="teams.confirmLeave.primaryAction" />
        </StyledButton>
        <OutlinedButton onClick={onCancel}>
          <FormattedMessage entry="teams.confirmLeave.secondaryAction" />
        </OutlinedButton>
      </ActionsContainer>
    </ModalContainer>
  </ModalWrapper>
);

ConfirmLeaveModal.propTypes = {
  isOpen: PropTypes.bool,
  teamName: PropTypes.string,
  isLoading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ConfirmLeaveModal;
