import { useRouter } from 'next/router';
import queryString from 'query-string';
import {
  HIDE_EXPRESS_SERVICE_PARAM,
  BACK_PARAM,
  CATALOG_ID_PARAM,
  CHECKOUT_CODE_PARAM,
  CHECKOUT_LOGIN_PARAM,
  CLAIM_PARAM,
  DINE_IN_DESCRIPTION_PARAM,
  DINE_IN_TYPE_PARAM,
  EMBED_LOCAL_TEST,
  EMBED_PARAM,
  EXPRESS_LOCAL_TEST,
  FEATURE_INFO_PARAM,
  FILTER_CITY_PARAM,
  FILTER_CUISINE_PARAM,
  FILTER_DELIVERY_PARAM,
  FILTER_LATITUDE_PARAM,
  FILTER_LONGITUDE_PARAM,
  FILTER_OPEN_PARAM,
  FILTER_PICKUP_PARAM,
  FILTER_SEARCH_PARAM,
  FILTER_TOP_PARAM,
  GO_CONSUMER_FLOW_PARAM,
  HIDE_DINE_IN_TOGGLE_PARAM,
  HIDE_PICKUP_PARAM,
  HQO_LINKOUT_PARAM,
  IN_STORE_PARAM,
  LANGUAGE_PARAM,
  LIST_TYPE_PARAM,
  MERCHANT_GROUP_REFERER_PARAM,
  ORDER_LATER_PARAM,
  ORIGIN_PARAM,
  OWNER_ID_PARAM,
  PICKUP_PARAM,
  PLACE_ID_PARAM,
  PROMO_PARAM,
  SUPPRESS_ORDER_BAR_PARAM,
  THEME_JSON_PARAM,
  WORKSPACE_ID_PARAM,
  HIDE_DOWNLOAD_APP_PARAM,
} from './constants';

const fields = [
  HIDE_EXPRESS_SERVICE_PARAM,
  THEME_JSON_PARAM,
  LANGUAGE_PARAM,
  CLAIM_PARAM,
  CHECKOUT_CODE_PARAM,
  BACK_PARAM,
  DINE_IN_DESCRIPTION_PARAM,
  DINE_IN_TYPE_PARAM,
  PICKUP_PARAM,
  HIDE_PICKUP_PARAM,
  OWNER_ID_PARAM,
  WORKSPACE_ID_PARAM,
  CATALOG_ID_PARAM,
  LIST_TYPE_PARAM,
  MERCHANT_GROUP_REFERER_PARAM,
  FILTER_LATITUDE_PARAM,
  FILTER_LONGITUDE_PARAM,
  ORDER_LATER_PARAM,
  EMBED_PARAM,
  HQO_LINKOUT_PARAM,
  FILTER_CITY_PARAM,
  FILTER_CUISINE_PARAM,
  FILTER_SEARCH_PARAM,
  FILTER_OPEN_PARAM,
  FILTER_PICKUP_PARAM,
  FILTER_DELIVERY_PARAM,
  FILTER_TOP_PARAM,
  PLACE_ID_PARAM,
  PROMO_PARAM,
  FEATURE_INFO_PARAM,
  SUPPRESS_ORDER_BAR_PARAM,
  EMBED_LOCAL_TEST,
  EXPRESS_LOCAL_TEST,
  GO_CONSUMER_FLOW_PARAM,
  ORIGIN_PARAM,
  CHECKOUT_LOGIN_PARAM,
  IN_STORE_PARAM,
  HIDE_DINE_IN_TOGGLE_PARAM,
  HIDE_DOWNLOAD_APP_PARAM,
];

const usePersistedQuery = ({ replace, omit, additionalParams } = {}) => {
  const router = useRouter();
  const query = router?.query || {};
  const reducedQuery = { ...additionalParams };
  if (replace) {
    Object.keys(replace).forEach(key => {
      reducedQuery[replace[key]] = query[key];
    });
  }

  fields.forEach(field => {
    reducedQuery[field] = reducedQuery[field] || query[field];
  });

  if (omit) {
    omit.forEach(key => {
      delete reducedQuery[key];
    });
  }

  if (queryString.stringify(reducedQuery)) {
    return `?${queryString.stringify(reducedQuery)}`;
  }
  return '';
};

export default usePersistedQuery;
